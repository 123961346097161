<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'

// local variables
const editHtmlDialog = ref(false)

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const html_block = ref(props.data.html_block)
const template = ref("html_block")

// functions
const edit_block = () => {
    editHtmlDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "html_block": html_block.value,
                "template": template.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editHtmlDialog.value = false
}

const add_block = () => {
   // editHtmlDialog.value = true;
    emits('blockCallback',
        {
            "template_data": {
                "html_block": "",
                "template": template.value
            },
            "action": "add",
            "index": props.index
        }
    )
}
 
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'menu'" class="card shadow-2 border-round-md py-3 px-3 mt-2">
        <div class="flex flex-row flex-wrap card-container">
            <div class="flex align-items-center justify-content-center mr-3">
                <Button v-tooltip.bottom="'Block hinzufügen'" class="p-button-success" icon="pi pi-plus" @click="add_block()" />
            </div>
            <div class="flex align-items-center justify-content-center mr-3 w-5rem border-gray-400 border-solid border-round-md border-1">
                <i class="pi pi-code" style="color:#2196F3;"></i>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
                HTML Block
            </div>
        </div>
    </div>
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <div v-if="props.template_type == 'data'" class="shadow-2 border-round-md px-3 py-1" @click="edit_block()" v-bind:style="{cursor: 'pointer'}">
        <div class="mt-3">
            <i class="pi pi-box mr-2 ml-1"></i>
            <span class="mt-1 font-bold" style="font-size: 1em; color: #9e9e9e">HTML Block</span>
        </div>
        <Divider />
        <span v-html="html_block"></span>
    </div>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editHtmlDialog" :style="{width: '80%'}" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-1">
                <Button label="Speichern" @click="save_block()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editHtmlDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
                <Button label="Löschen" @click="delete_block()" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-trash" />
            </div>
            <div class="field col-12 mt-3">
                <Textarea id="html_block" :autoResize="true" rows="12" v-model="html_block" style="background-color: #1e1e1e; color:#ffebcd" />
            </div>
        </div>
    </Dialog>
</template>