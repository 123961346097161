
<style>
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
</style>

<style lang="scss" scoped>
@import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <LoadingSpinner v-if="loader" size="5" color="fill-primary-600" classs="m-5" />
    <Toolbar>

        <template #start>
            <Button v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
            <Button @click="save_changes()" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" />
            <Divider layout="vertical" />
            <Button v-tooltip.bottom="'Preview Artikel'" @click="preview_blog()" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" />
            <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab()" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" />
        </template>
        <template #end>
            <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" />
        </template>
    </Toolbar>
    <TabView class="col-12 mt-2" ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <Toolbar>
                <template #start>
                    <Button label="ChatGPT3 KI" @click="chatGptDialog = true" class="w-auto mr-2 p-button bg-primary-600 text-white" v-tooltip.top="'Generieren KI Text mit ChatGPT3'" icon="pi pi-cloud" />
                    <!-- <Button label="DeepL Translate" class="w-auto mr-2 p-button" v-tooltip.top="'Übersetzen Sie den Text mit DeepL'" style="background-color: var(--indigo-500); border-color: background-color: var(--indigo-700);" icon="pi pi-cloud" />
                        <Button label="Stage Anlyzer" class="w-auto mr-2 p-button" style="background-color: var(--indigo-500); border-color: background-color: var(--indigo-700);" icon="pi pi-cloud" />
                        <Button label="Google Keyword Planner" class="w-auto mr-2 p-button" style="background-color: var(--indigo-500); border-color: background-color: var(--indigo-700);" icon="pi pi-cloud" />
                        <Button label="Google Questions Generator" class="w-auto mr-2 p-button" style="background-color: var(--indigo-500); border-color: background-color: var(--indigo-700);" icon="pi pi-cloud" /> -->

                    <!-- <Button label="Teaser Vorschlag" class="w-auto mr-2 p-button bg-primary-600 text-white" v-tooltip.top="'Generieren Sie einen Text auf Basis der Überschrift'" icon="pi pi-cloud" @click="ai_question" :disabled="disabled" /> -->

                    <Button label="DeepL Translate" class="w-auto mr-2 p-button bg-primary-600 text-white" v-tooltip.top="'Übersetzen Sie den Text mit DeepL'" icon="pi pi-cloud" />
                    <Button label="Stage Anlyzer" class="w-auto mr-2 p-button bg-primary-600 text-white" icon="pi pi-cloud" />
                    <Button label="Google Keyword Planner" class="w-auto mr-2 p-button bg-primary-600 text-white" icon="pi pi-cloud" />
                    <Button label="Google Questions Generator" class="w-auto mr-2 p-button bg-primary-600 text-white" icon="pi pi-cloud" />
                </template>
            </Toolbar>
            <div class="col-12 formgrid grid">
                <div class="field col-8 mt-3">
                    <Editor editorStyle="width: 100%; min-height: 850px;" v-model="blog.text_content">
                        <template v-slot:toolbar>
                            <span class="ql-formats">
                                <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            </span>
                        </template>
                    </Editor>
                </div>
                <div class="field col-4">
                    <div class="field col-12">
                        <Accordion class="accordion-custom mt-3" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2" style="font-size: 130%;">Metadaten</span>
                                </template>
                                <div class="field col-12 mt-5">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-auto" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>
                                <div class="field col-12 mt-5">
                                    <span class="p-float-label">

                                        <!-- <Dropdown id="blog_category" v-model="blog.category" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto" /> -->
                                        <MultiSelect v-model="blog.category" :options="blog_categories" optionLabel="name" optionValue="value" placeholder="Kategorien wählen" :maxSelectedLabels="3" class="w-52" />
                                        <label for="blog_category">Kategorien</label>
                                    </span>
                                </div>
       

                                <div class="field col-12 mt-5">
                                    <span class="p-float-label">
                                        <Textarea id="title" style="width:100%; min-height: 50px;" :autoResize="true" v-model="blog.title" />
                                        <label for="title">Titel</label>
                                    </span>
                                </div>
                                <div class="field col-12 mt-5">
                                        <span class="p-float-label">

                                            <!-- <Dropdown id="blog_category" v-model="blog.category" :options="blog_categories" optionLabel="name" optionValue="value" class="w-auto" /> -->
                                            <!-- <MultiSelect v-model="blog.tags" :options="blog_tags" optionLabel="value" optionValue="value" placeholder="Tags wählen" :maxSelectedLabels="3" class="w-52" /> -->
                                       

                                            <MultiSelect v-model="blog.tags" :options="blog_tags" optionLabel="value" optionValue="value"  placeholder="Tags wählen" class="w-52" selectedItemsLabel="{0} Tags ausgewählt">

                                                <template #footer>
                                                    <div class="py-2 px-3">
                                                        <b>{{ blog.tags ? blog.tags.length : 0 }}</b> tag{{ (blog.tags ? blog.tags.length : 0) > 1 ? 's' : '' }} ausgewählt.
                                                    </div>
                                                </template>
                                            </MultiSelect>
                                             <label for="blog_tags">Tags</label>
                                        </span>
                                    </div>
                                <!-- <div class="field col-12 mt-5">
                                    <span class="p-float-label">
                                        <Textarea id="teaser" style="width:100%; min-height: 100px;" :autoResize="true" v-model="blog.teaser" />
                                        <label for="teaser">Teaser</label>
                                    </span>
                                </div>  -->
                                <!-- <div class="field col-12 mt-5">
                                    <span class="p-float-label">
                                        <Dropdown id="author" v-model="blog.author" :options="users" optionLabel="user_fullname" optionValue="user_email" class="w-auto" />
                                        <label for="author">Autor</label>
                                    </span>
                                </div> -->
                                <div class="field col-12 mt-5">

                                    <span class="p-float-label">
                                        <!-- <Dropdown :filter="true" :showClear="true" id="user" v-model="blog.user" :options="users" optionLabel="user_fullname" optionValue="user_email" class="w-auto" /> -->
                                        <MultiSelect v-model="blog_network_users" :options="network_users" optionLabel="name" optionValue="email" placeholder="Kategorien wählen" :maxSelectedLabels="3" class="w-52" />
                                        <label for="user">Bearbeiter</label>
                                    </span>
                                </div>

                                <div class="field col-12 mt-5">
                                    <div class="flex align-items-center">
                                        <Checkbox v-model="blog_top_entry" :binary="true" />
                                        <label for="ingredient1" class="ml-2">TopBeitrag</label>
                                    </div>
                                </div>

                                <div class="field col-12 mt-2">
                                    <Button @click="editTitleImageDialog = true" v-tooltip.bottom="'Titel-Bild editieren'" label="Titel-Bild" class="w-full mr-2 p-button mb-2" icon="pi pi-image" />
                                    <Button @click="editTitleImagePreviewDialog = true" v-tooltip.bottom="'Vorschau-Bild editieren'" label="Vorschau-Bild" class="w-full p-button" icon="pi pi-image" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="field col-12 mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2" style="font-size: 130%;">Bilder Bibliotek</span>
                                    <Avatar :label="String(image_library.length)" class="mr-2" shape="circle" />
                                </template>
                                <Galleria :value="image_library" v-model:activeIndex="activeImageIndex" :numVisible="7" containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false" v-model:visible="displayGallery">
                                    <template #item="slotProps">
                                        <img :src="slotProps.item.url" style="width: 100%; display: block;" />
                                    </template>
                                </Galleria>
                                <div v-if="image_library" class="grid">
                                    <div v-for="(image, index) of image_library" class="flex" :key="index">
                                        <img :src="image.url" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" @click="showGallery(index)" />
                                    </div>
                                </div>
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                    <!-- <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder aus der Bibliotek wählen'" label="Bibliotek" class="mr-2 p-button-success w-auto" icon="pi pi-folder" /> -->
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="field col-12 mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2" style="font-size: 130%;">Änderungsverlauf</span>
                                    <Avatar :label="String(blog_changes.length)" class="mr-2" shape="circle" />
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes" :key="index">
                                        <Card style="width: 100%" class="mt-3 shadow-3">
                                            <template #content>
                                                <div class="flex">
                                                    <Chip :label="change.user" icon="pi pi-user" class="mr-3" />
                                                    <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" />
                                                </div>
                                                <div class="flex">
                                                    <span class="m-2 p-2 w-full" style="background-color: #b3e5fc; color: #23547b; border-radius: 3px;">{{ change.message }}</span>
                                                </div>
                                            </template>
                                        </Card>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-box mr-2"></i>
                <span class="mr-3">Builder</span>
            </template>
            <div class="col-12 formgrid grid">
                <div class="field col-8 mt-3 p-0" style="border: 2px solid #e9ecef;">
                    <!--------------------------------------------------->
                    <!------------------ DataTable ---------------------->
                    <!--------------------------------------------------->
                    <DataTable :value="blog_content" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
                        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                        <Column field="template">
                            <template #header>
                                <i class="pi pi-box mr-2"></i>
                                <span class="mr-3">Blocks</span>
                                <Avatar :label="String(blog_content.length)" shape="circle" class="mr-2 bg-primary-500 text-white" />
                            </template>
                            <template #body="slotProps">
                                <StandardText v-if="slotProps.data.template == 'standard_text'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />
                                <StandardTitle v-if="slotProps.data.template == 'standard_title'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />
                                <StandardImage v-if="slotProps.data.template == 'standard_image'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                                <CTAButton v-if="slotProps.data.template == 'cta_button'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />
                                <HtmlBlock v-if="slotProps.data.template == 'html_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" />
                                <QuoteBlock v-if="slotProps.data.template == 'quote_block'" :template_type="'data'" :index="slotProps.index" :data="slotProps.data" @block-callback="update_template_data" :workflow_id="workflow_id" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div class="field col-4 mt-3">
                    <div class="field col-12 mt-4">
                        <div v-for="key in content_blocks" :key="key">
                            <StandardText v-if="key == 'standard_text'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <StandardTitle v-if="key == 'standard_title'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <StandardImage v-if="key == 'standard_image'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :image_library="image_library" :workflow_id="workflow_id" />
                            <CTAButton v-if="key == 'cta_button'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <HtmlBlock v-if="key == 'html_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" />
                            <QuoteBlock v-if="key == 'quote_block'" :template_type="'menu'" :data="content_blocks" @block-callback="update_template_data" :workflow_id="workflow_id" />
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="imageUploadDialog" :style="{ width: '800px' }" header="Bilder hochladen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div>
                            <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                            <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Teaser Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image == false" class="field col-12 mt-3">
                <img :src="blog.header_image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <cropper ref="title_image_cropper" :stencil-props="{ aspectRatio: 2 / 1 }" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper, 'header_image')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
                <!-- <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" /> -->
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image_preview == false" class="field col-12 mt-3">
                <img :src="blog.header_image_preview" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <cropper ref="title_image_cropper_preview" :stencil-props="{ aspectRatio: 1 / 1 }" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.url" :src="img.url" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_header_image_preview(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
                <!-- <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" /> -->
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit GPT AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ChatGPT from '../Editor-Recipes/chat_gpt.vue'
import StandardText from '../Editor-Blocks/standard_text.vue'
import StandardImage from '../Editor-Blocks/standard_image.vue'
import StandardTitle from '../Editor-Blocks/standard_title.vue'
import CTAButton from '../Editor-Blocks/cta_button.vue'
import HtmlBlock from '../Editor-Blocks/html_block.vue'
import QuoteBlock from '../Editor-Blocks/quote_block.vue'
import Simplestore from '@/core/var/Simplestore';

const content_blocks = ref(['standard_text', 'standard_title', 'standard_image', 'cta_button', 'html_block'])
const channel = ref(useCustomerStore().getCustomer.customer);


const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)

const props = defineProps({
    redis_key: { type: String }
})

const workflow_id = ref("")
const blog = ref([])
const blog_users = ref([])
const blog_network_users = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const blog_sort = ref([])
const blog_status = ref([])
const blog_top_entry = ref(false);
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null);
const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_button_loading = ref(false);
const blog_text_vorschlag_dialog = ref(false);
const network_users = ref([]);
const disabled = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])

onMounted(() => {

    loader.value = true;

    getBlog().then(() => {
        loader.value = false;
    });

    fetchSystemAndUsers();
})
watch(loader, res => {
    Simplestore.spinner = res;
});


const fetchSystemAndUsers = async () => {
    try {
        const systemRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-system`);
        const usersRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-users`);
        const networkRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-network`);

        const [systemResponse, usersResponse, networkResponse] = await Promise.all([systemRequest, usersRequest, networkRequest]);

        blog_categories.value = systemResponse.data.blog_categories;
        blog_tags.value = systemResponse.data.blog_tags;
        blog_sort.value = systemResponse.data.blog_sort;
        blog_status.value = systemResponse.data.blog_status;
        network_users.value = networkResponse.data.filter(item => item.status === 'published');
        users.value = usersResponse.data;
    } catch (error) {
        console.error("Error fetching system and users data:", error);
    }
};


const getBlog = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/get-blog`, payload);
        const { data } = response;

        blog.value = data;
        blog_top_entry.value = data.top_entry;
        blog.value.category = data.category[0];
        workflow_id.value = data.workflow_id;
        blog_content.value = data.content;
        blog_title.value = data.title;
        blog_users.value = data.user;
        blog_network_users.value = data.author;
        blog_changes.value = data.changes;
        image_library.value = data.image_library;
        GPT_Primer(blog_title.value);

    } catch (error) {
        console.error("Error fetching blog data:", error);
    }
};

const GPT_Primer = (blog_title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle ein Blog Post zum Thema ${blog_title}`,
            button_name: "Blog Post erstellen",
        },
        {
            question: `Erstelle ein Blog Post zum Thema ${blog_title} und fasse es mit 100 Wörtern zusammen`,
            button_name: "Blog Zusammenfassung (100 Wörter)",
        },
    ];
}

const update_text_content = (e) => {
    page.value["text_content"] += "<br><br>" + e.chatGptAnswers
    toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalte wurden übernommen', life: 3000 })
    chatGptDialog.value = false
}


const close_tab = () => {
    confirm.require({
        message: 'Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

const showGallery = (index) => {
    activeImageIndex.value = index
    displayGallery.value = true
}

const update_template_data = (e) => {
    if (e.action == "save") {
        blog_content.value[e.index] = e.template_data
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000 })
    } else if (e.action == "delete") {
        blog_content.value.splice(e.index, 1)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000 })
    } else if (e.action == "add") {
        blog_content.value.push(e.template_data)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000 })
    }
}

const save_changes = () => {
    blog.value["content"] = blog_content.value
    blog.value["changes"] = blog_changes.value
    blog.value["image_library"] = image_library.value
    blog.value["top_entry"] = blog_top_entry.value;
    blog.value["category"] = [blog.value.category];
    blog.value['user'] = blog_users;
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + "/save-blog", blog.value)
        .then(response => {
            if (response.data.status == "success") {
                toast.add({ severity: 'success', summary: blog.value.title + ' gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000 })

            }
            if (response.data.status == "titlechanged") {
                toast.add({ severity: 'success', summary: blog.value.title + ' gespeichert', detail: 'Änderungen wurden erfolgreich gespeichert', life: 3000 })
                emits('editorCallback',
                    {
                        "action": "close_tab",
                        "redis_key": props.redis_key
                    }
                )
            }
            // tmp hack
            blog.value["category"] = blog.value.category[0];
        })
}

const reset_changes = () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            let payload = { "redis_key": props.redis_key }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + "/get-blog", payload)
                .then(response => {
                    blog.value = response.data
                })
        }
    })
}

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        blog_content.value = e.value
    }
}


const crop_header_image = async (image, image_type) => {
    let { canvas } = image.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = { "file": reader.result, "image_type": image_type, "workflow_id": blog.value.workflow_id }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + "/upload-image", payload)
                .then(response => {
                    toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000 })
                    blog.value.header_image = response.data.image_url
                    edit_header_image.value = false
                })
        }
    })
}

const title_image_cropper_preview = ref()
const crop_header_image_preview = async (image, image_type) => {
    let { canvas } = image.getResult()
    await canvas.toBlob((blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = async () => {
            let payload = { "file": reader.result, "image_type": image_type, "workflow_id": blog.value.workflow_id }
            await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + "/upload-image", payload)
                .then(response => {
                    toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Bild erfolgreich hochgeladen', life: 3000 })
                    blog.value.header_image_preview = response.data.image_url
                    edit_header_image_preview.value = false
                })
        }
    })
}

const upload_library_image = async (e) => {
    console.log(blog.value);
    for (let i = 0; i < e.files.length; i++) {
        let reader = new FileReader()
        reader.readAsDataURL(e.files[i])
        reader.onload = () => {
            let payload = { "filename": e.files[i].name, "file": reader.result, "type": e.files[i].type, "size": e.files[i].size, "redis_key": props.redis_key, "workflow_id": blog.value.workflow_id }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/" + channel.value + "/upload-library-image", payload)
                .then(response => {
                    image_library.value = response.data
                    toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Bild ' + e.files[i].name + ' erfolgreich hochgeladen', life: 3000 })
                })
        }

        await new Promise(resolve => setTimeout(resolve, 2000));
    }
}

const preview_blog = (redis_key) => {
    let day = new Date().getDate()
    let cs = (1337 * 9999) + day
    window.open("https://www.itmc.ch/preview/blog/?key=" + redis_key + "&preview=" + cs)
}

const swiss_date_time = (date) => {
    let parsed_date = new Date(date)
    return parsed_date.toLocaleDateString('de-CH') + " @ " + parsed_date.toLocaleTimeString('de-CH')
}


const ai_question = () => {
    blog_text_vorschlag_button_loading.value = true;
    let payload = { "question": blog_title.value }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/itmc/ai-question", payload)
        .then(response => {
            blog_text_vorschlag.value = response.data.message;
            console.log(response.data);
            blog_text_vorschlag_dialog.value = true;
            blog_text_vorschlag_button_loading.value = false;
        })
}
</script>
