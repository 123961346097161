import axios from 'axios'
import 'axios'

const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) =>
    first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('')

export { capitalizeFirstLetter }



const genPasswd = async payload => {
    const JWTtoken = localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME);
    axios.defaults.headers.common['Authorization'] = `${JWTtoken}`;

    const request = axios.post("/datahub/genpasswd", { clean: payload });

    return request
        .then(result => {
            return result;
        })
        .catch(error => { return Promise.reject(error); });
}
export { genPasswd };



const getRandomId = (length = 16) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};
export { getRandomId };

const slugify = (str) => str.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().trim().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-');
export { slugify };

const swiss_date = (date) => new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit' })
export { swiss_date };

const swiss_date_time = (date) => new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
export { swiss_date_time };

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export { getCurrentDate };

const characters_left = (field, limit) => {
    let chars = field.length
    return limit - chars
}
export { characters_left };

const tags2array = str => {
    const words = str.split(/[, |\s]+/);
    const filteredWords = words.filter(word => word.trim() !== '');
    return filteredWords;
};
export { tags2array };

const captions2array = str => {
    const words = str.split(/;\s*/);
    const filteredWords = words.filter(word => word.trim() !== '');
    return filteredWords;
};
export { captions2array };

const array2captions = arr => {
    return arr.join('; ');
};
export { array2captions };

const arrayToString = arr => arr.join(' ');
export { arrayToString };

const createSlugfromName = (name) => {
    return name.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
}
export {createSlugfromName}

const checkSlugExists = (array, slug) => {
    return array.some(item => item.slug === slug);
}
export {checkSlugExists}