<template>
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button v-if="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes()" class="mr-2 p-button-danger" icon="pi pi-refresh" />
            <Button :disabled="changes != 0 ? false : true" @click="save_changes()" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto mr-2 p-button-success" icon="pi pi-save" />
            <Divider layout="vertical" />
            <Button @click="newreferencesDialog = true, references_name = '', references_role = '', references_email = '', references_phone = ''" label="Referenz hinzufügen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Referenz erfassen'" icon="pi pi-plus" />
            <Button @click="imageUploadDialog = true" label="Bilder hochladen" class="w-auto mr-2 p-button" v-tooltip.top="'Bilder in die Referenz Bibliothek hochladen'" icon="pi pi-upload" />
        </template>
    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_references['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_references" :value="references" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onreferencesRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="status" header="Status" style="width: 100px">
            <template #body="slotProps">
                <Chip v-if="slotProps.data.status == 'draft'" :label="get_status(slotProps.data.status)" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #d32f2f; color: #FFF" />
                <Chip v-if="slotProps.data.status == 'published'" :label="get_status(slotProps.data.status)" v-tooltip.top="'Beitrag ist Publiziert'" class="mr-1" style="background-color: #689f38; color: #FFF" />
            </template>
        </Column>
        <Column field="image" class="w-50">
            <template #body="slotProps">
                <img :src="slotProps.data.logo" width="64" class="shadow-2 mr-2" style="vertical-align: middle; width: 64px; height: 64px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
            </template>
        </Column>
        <Column field="name" header="Titel" sortable>
            <template #body="slotProps">
                <span class="font-bold">{{ slotProps.data.title }}</span>
            </template>
        </Column>

        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false"
                    @click="editreferencesDialog = true, edit_image = false, edit_avatar = false, references_title = slotProps.data.title, references_tags = slotProps.data.tags, references_projecttext = slotProps.data.projecttext, references_resulttext = slotProps.data.resulttext, references_logo = slotProps.data.logo, references_status = slotProps.data.status, references_index = slotProps.index, references_content = slotProps.data.content, references_workflow_id = slotProps.data.workflow_id"
                    class="p-button-rounded p-button-text" v-tooltip.top="'Referenz bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.workflow_id)" class="p-button-rounded p-button-text" v-tooltip.top="'Referenz löschen'" icon="pi pi-trash" />
            </template>
        </Column>
    </DataTable>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------ Edit references ---------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editreferencesDialog" :style="{ width: '80%' }" header="Referenz bearbeiten" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <img :src="references_logo" class="shadow-2" style="width: 400px;" />
            </div>
            <!-- <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <img :src="references_avatar" class="shadow-2" style="width: 300px;" />
            </div> -->
            <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <Button v-if="edit_image == false" label="Bild Bearbeiten" @click="edit_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            </div>
            <!-- <div v-if="edit_image == false && edit_avatar == false" class="field col-6 mt-3">
                <Button v-if="edit_image == false" label="Avatar Bearbeiten" @click="edit_avatar = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            </div> -->
            <div v-if="edit_image == true" class="field col-12 mt-3">
                <cropper ref="croppy" :src="references_logo" :auto-zoom="true" :auto-center="true" />
            </div>
            <!-- <div v-if="edit_avatar == true" class="field col-12 mt-3">
                <cropper ref="croppy_avatar" :src="references_image" :auto-zoom="true" :auto-center="true" :stencil-props="{ aspectRatio: 1 / 1 }" />
            </div> -->
            <div v-if="edit_image == true || edit_avatar == true" class="field col-12 mt-3">
                <template v-for="(img, index) of image_library" :key="index">
                    <img @click="references_logo = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; height: 100px; width: 100px; object-fit: cover; display:inline;" />
                </template>
            </div>
            <div v-if="edit_image == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_image()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
            <div v-if="edit_avatar == true" class="field col-12 mt-3">
                <Button label="Avatar Speichern" @click="crop_avatar()" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>

            <div class="field col-6 mt-1">
                <label for="title">Titel</label>
                <InputText v-model="references_title" id="title" />
            </div>

            <div class="field col-6 mt-1">
                <label for="tags">Tags</label>
                <!-- <InputText v-model="references_tags" id="tags" /> -->
                <MultiSelect v-model="references_tags" selectedItemsLabel="{0} Tags ausgewählt" :options="system_tags" optionLabel="value" optionValue="value" placeholder="Tags wählen" :maxSelectedLabels="3" class="w-full" />
                <label for="system_tags">Tags</label>
            </div>
            <div class="field col-6 mt-1">
                <label for="projecttext">Projekt Text</label>
                <Textarea v-model="references_projecttext" id="projecttext"></Textarea>
            </div>
            <div class="field col-6 mt-1">
                <label for="resulttext">Resultat Text</label>
                <Textarea v-model="references_resulttext" id="resulttext"></Textarea>
            </div>
            <div class="field col-6 mt-1">
                <label for="status">Status</label>
                <Dropdown v-model="references_status" id="status" :options="status" optionLabel="label" optionValue="value" />
            </div>
            <!-- <div class="field col-6 mt-1">
                <label for="name">Name</label>
                <InputText v-model="references_name" id="name" />
            </div>
            <div class="field col-6 mt-1">
                <label for="role">Rolle</label>
                <Dropdown v-model="references_role" id="status" :options="member_status" placeholder="Status auswählen" optionLabel="label" optionValue="value" />
            </div>
            <div class="field col-6 mt-1">
                <label for="email">E-Mail</label>
                <InputText v-model="references_email" id="email" />
            </div>
            <div class="field col-6 mt-1">
                <label for="phone">Tel-Nr.</label>
                <InputText v-model="references_phone" id="phone" />
            </div>
            <div class="field col-6 mt-1">
                <label for="status">Status</label>
                <Dropdown v-model="references_status" id="status" :options="status" optionLabel="label" optionValue="value" />
            </div>
            <div class="field col-6 mt-1">
                <label for="external">Extern</label>
                <Dropdown v-model="references_external" id="external" :options="external" optionLabel="label" optionValue="value" />
            </div>
            <div class="field col-12 mt-1">
                <label for="content">Beschreibung</label>
                <Editor editorStyle="width: 100%; min-height: 200px;" v-model="references_content" id="content">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
            </div> -->
            <div class="field col-12 mt-1">
                <Button label="Speichern" @click="save_references(references_index), editreferencesDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editreferencesDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------ New references ---------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="newreferencesDialog" :style="{ width: '80%' }" header="Referenz hinzufügen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-1">
                <label for="title">Name</label>
                <InputText v-model="references_title" id="title" />
            </div>

            <div class="field col-6 mt-1">
                <label for="tags">Tags</label>
                <MultiSelect v-model="references_tags" :options="system_tags" selectedItemsLabel="{0} tags ausgewählt" optionLabel="value" optionValue="value" placeholder="Tags wählen" :maxSelectedLabels="3" class="w-full" id="add_tags" />
                <label for="system_tags">Tags</label>
            </div>
            <div class="field col-6 mt-1">
                <label for="phone">Projekt Text</label>
                <Textarea v-model="references_projecttext"></Textarea>
            </div>
            <div class="field col-6 mt-1">
                <label for="phone">Resultat Text</label>
                <Textarea v-model="references_resulttext"></Textarea>
            </div>
            <div class="field col-12 mt-1">
                <Button :disabled="references_title == '' || references_resulttext == '' || references_projecttext == ''" label="Speichern" @click="new_references(), newreferencesDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newreferencesDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!---------------- Image Library -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="imageUploadDialog" :style="{ width: '800px' }" header="Bilder hochladen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div>
                            <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                            <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>
</template>
<style lang="scss" scoped>
@import '@/core/assets/primevue/primeflex.scss';
</style>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Simplestore from '@/core/var/Simplestore';
import { Cropper, Preview } from 'vue-advanced-cropper'
import { useCustomerStore } from '@/core/store/CustomerStore'
import 'vue-advanced-cropper/dist/style.css';
import { getRandomId, getCurrentDate, tags2array } from '@/core/var/tools'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const references = ref([])
const references_id = ref();
const references_index = ref();
const references_title = ref(null)
const references_tags = ref(null)
const references_status = ref(null)
const references_projecttext = ref(null)
const references_logo = ref(null)
const references_resulttext = ref(null)
const references_workflow_id = ref(null)
const system_tags = ref([]);
const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editreferencesDialog = ref(false)
const newreferencesDialog = ref(false)
const imageUploadDialog = ref(false)
const image_library = ref([])
const edit_image = ref(false)
const edit_avatar = ref(false)
const force_save = ref(false)
const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])
const member_status = ref([
    { label: 'Mitglied', value: 'member' },
    { label: 'Referenz', value: 'consultant' }
])
const external = ref([
    { label: 'Nein', value: false },
    { label: 'Ja', value: true }
])
const croppy = ref(null)
const croppy_avatar = ref(null)
const filter_references = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })

const test = ref([
    { label: 'Mitglied', value: 'member' },
    { label: 'Referenz', value: 'consultant' }
]);

const slug = ref(useCustomerStore().getCustomer.customer);



onMounted(() => {
    loader.value = true;
    init().then(() => {
        loader.value = false;
    });
});

watch(loader, res => {
    Simplestore.spinner = res;
});

const init = async () => {
    try {
        const [referencesResponse, imageLibraryResponse, systemResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/get-references`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/get-references-image-library`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/get-system`),
        ]);
        references.value = referencesResponse.data;
        system_tags.value = systemResponse.data.blog_tags;
        console.log(system_tags.value);
        image_library.value = imageLibraryResponse.data;
    } catch (error) {
        console.error(error);
    }
}

const reset_changes = async () => {

    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/get-references`);
        references.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onreferencesRowReorder = (e) => {
    references.value = e.value
    incrementSaves();
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    for (let i = 0; i < status.value.length; i++) {
        if (status.value[i].value == status_value) {
            if (status_value == "published") {
                return "Aktiv"
            }
            if (status_value == "draft") {
                return "Inaktiv"
            }
        }
    }
}

const crop_image = () => {
    references_logo.value = croppy.value.getResult().canvas.toDataURL()
    upload_image().then(() => {
        edit_image.value = false;
    })
}

// const crop_avatar = () => {
//     references_avatar.value = croppy.value.getResult().canvas.toDataURL()
//     upload_avatar()
// }


const upload_image = async () => {
    try {
        const { canvas } = croppy.value.getResult();
        await canvas.toBlob(async (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = async () => {
                const payload = {
                    file: reader.result,
                    image_type: "image",
                    workflow_id: references_workflow_id.value
                };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/upload-image`,
                    payload
                );
                references_logo.value = response.data.image_url;
            };
        });
    } catch (error) {
        console.error(error);
    }
};



// const upload_avatar = async () => {
//     try {
//         const { canvas } = croppy_avatar.value.getResult();
//         await canvas.toBlob(async (blob) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(blob);
//             reader.onload = async () => {
//                 const payload = {
//                     file: reader.result,
//                     image_type: "avatar",
//                     workflow_id: references_workflow_id.value
//                 };
//                 const response = await axios.post(
//                     `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/upload-image`,
//                     payload
//                 );
//                 references_avatar.value = response.data.image_url;
//             };
//         });
//     } catch (error) {
//         console.error(error);
//     }
// };



const upload_library_image = async (e) => {
    try {
        for (let i = 0; i < e.files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(e.files[i]);
            reader.onload = async () => {
                const payload = { filename: e.files[i].name, file: reader.result, workflow_id: references_workflow_id.value };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/upload-references-library-image`,
                    payload
                );
                image_library.value = response.data;
                toast.add({
                    severity: "success",
                    summary: "Erfolg",
                    detail: `Bild ${e.files[i].name} erfolgreich hochgeladen`,
                    life: 3000
                });
            };
            await new Promise(resolve => setTimeout(resolve, 2000));
        }
    } catch (error) {
        console.error(error);
    }
};


const save_references = (index) => {

    references.value[index]["workflow_id"] = getRandomId(6);
    references.value[index]["title"] = references_title.value;
    references.value[index]["logo"] = references_logo.value;
    references.value[index]["tags"] = references_tags.value;
    references.value[index]["resulttext"] = references_resulttext.value;
    references.value[index]["projecttext"] = references_projecttext.value;
    references.value[index]["status"] = references_status.value;
    references.value[index]["dc"] = getCurrentDate();
    incrementSaves();
}

const new_references = () => {
    references.value.unshift({
        "workflow_id": getRandomId(6),
        "title": references_title.value,
        "logo": references_logo.value,
        "status": "draft",
        "tags": references_tags.value,
        "resulttext": references_resulttext.value,
        "projecttext": references_projecttext.value,
        "content": "",
        "dc": getCurrentDate()
    })

    force_save.value = true
    incrementSaves();
}


const delete_dataset = (index) => {
    const newt = references.value.filter(dataset => dataset.workflow_id !== index);
    references.value = newt;
    incrementSaves();
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}


const save_changes = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/save-reference`,
            references.value
        );
        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

</script>