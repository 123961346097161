<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import axios from 'axios'
// define props
const props = defineProps({
    questions: { type: Object, required: true }
})
const emits = defineEmits(['chatCallback'])

const gpt_content = ref("")
const gpt_answer = ref("")
const loader = ref(false)

const gpt_question = (question) => {
    loader.value = true
    let payload = { "question": question }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/itmc/chatgpt-question", payload)
    .then(response => {
        gpt_answer.value = response.data.message
        gpt_content.value = gpt_content.value + "<br><br>======================================================================================================================<br><br>" + gpt_answer.value.replace('\n', '<br><br>') + "<br><br>======================================================================================================================<br><br>" 
        loader.value = false
    })
}
const ai_question = (question) => {
    loader.value = true
    let payload = { "question": question }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/itmc/chatgpt-question", payload)
        .then(response => {
            gpt_answer.value = response.data.message
            gpt_content.value = gpt_content.value + "<br><br>======================================================================================================================<br><br>" + gpt_answer.value.replace('\n', '<br><br>') + "<br><br>======================================================================================================================<br><br>"
            loader.value = false
        })
}

const save_answers = () => {
    emits('chatCallback',
        {
            "chatGptAnswers": gpt_content.value,
        }
    )
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <BlockUI :blocked="loader">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-1">
                <template v-for="(q, index) in props.questions" :key="index">
                    <Button :label="q.button_name" @click="gpt_question(q.question)" class="w-auto mr-2 mb-1 p-button" icon="pi pi-bolt" />
                </template>
            </div>
            <div class="field col-12 mt-3">
                <Editor editorStyle="width: 100%; min-height: 550px;" v-model="gpt_content">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-12 mt-3">
                <Button label="Texte übernehmen" @click="save_answers()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
            </div>
        </div>
    </BlockUI>
</template>